import { FC, useContext, useEffect, useState } from "react";
import { BlogComponent } from "../../components/blog-component";
import { SocketContext } from "../../utils/hooks/context/socket-context-hooks";
import { getSocketId } from "../../utils/functions/upt-function";
import { MetaTagComponent } from "../../components/meta-tag-component";

export const BlogPage: FC = () => {
  const { socketError, setSocketState, get, res } = useContext(SocketContext),
    [state, setActualState] = useState<any>({
      loading: true,
      hasMore: true,
      pageNumber: 1,
      data: [],
      search: "",
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    getBlog = (pageNumber = 1) => {
      setState({ loading: true });
      get("blog-2", {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 9,
        pageNumber,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "search",
              option: "cn",
              type: "string",
              data: state.search,
            },
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      });
    };

  useEffect(() => {
    setState({ data: [] });
    getBlog();
  }, []);

  useEffect(() => {
    if (res.url === `${getSocketId()}-blog-2`) {
      state.data = [...state.data, ...res.data.data];
      state.hasMore = state.data.length <= res.data.records;
      state.pageNumber = res.data.pageNumber;
      state.loading = false;
      setState(state);
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
    }
  }, [socketError]);
  return (
    <>
      <MetaTagComponent
        {...{
          keywords: "blog, our principles",
          title: "Enviro Quotes | Our Principles",
          description:
            "We want share something exciting with you. Our our principles where you will find latest news / relevant updates , as well as client testimonials and comments",
          url: "http://enviroquotes.co.za/our-principles",
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Our Principles</h1>
          </div>
        </div>
      </section>

      <section aria-label="section">
        <div className="container">
          <div className="row wow fadeIn">
            <div className="col-lg-12">
              <div className="items_filter">
                <form
                  className="row form-dark"
                  id="form_quick_search"
                  method="post"
                  name="form_quick_search"
                >
                  <div className="col">
                    <h3 className="mb-3">Our Principles</h3>
                    <input
                      className="form-control"
                      id="name_1"
                      name="name_1"
                      placeholder="search item here..."
                      type="text"
                      onChange={(e) => {
                        state.search = e.target.value;
                        state.data = [];
                        setState(state);
                        getBlog();
                      }}
                    />
                    <a href="#" id="btn-submit">
                      <i className="fa fa-search bg-color-secondary"></i>
                    </a>
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>

            {state.loading ? (
              <div className="col-12 d-flex justify-content-center">
                <div className="spinner"></div>
              </div>
            ) : (
              <>
                {state.data.map((b: any) => (
                  <BlogComponent {...b} />
                ))}

                {state.hasMore ? (
                  <div className="col-md-12 text-center">
                    <button
                      onClick={() => getBlog(state.pageNumber + 1)}
                      className="btn-main wow fadeInUp lead"
                    >
                      Load more
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
