import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { SettingsContext } from "../utils/hooks/context/settings-context-hooks";

export const FooterComponent: FC = () => {
  const { settingsState } = useContext(SettingsContext);

  return (
    // <footer style={{ padding: 0 }}>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>More</h5>
              <ul>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Useful Links</h5>
              <ul>
                <li>
                  <Link to="/create-quote">Request a quote</Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://dashboard.enviroquotes.co.za/"
                  >
                    Become a Service Provider
                  </a>
                </li>
                <li>
                  <Link to="/services">Examples of our services</Link>
                </li>
                <li>
                  <Link to="/our-principles">Our Principles</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Contact Us</h5>
              <ul>
                {settingsState.supportPhoneNumber
                  .replace(/ /g, "")
                  .split(",")
                  .map((p: string) => (
                    <li>
                      <a href={`tel:${p}`}>
                        <i className="icon_phone"></i> {p}
                      </a>
                    </li>
                  ))}
                <li></li>
                {settingsState.supportEmail.split(",").map((e: string) => (
                  <li>
                    <a href={`mailto:${e}`.split("(")[0]}>
                      <i className="icon_mail"></i> {e}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <Link to="/">
                    <span className="copy">
                      &copy; Copyright {new Date().getFullYear()} - Enviro
                      Quotes
                    </span>
                  </Link>
                </div>
                <div className="de-flex-col">
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/enviroquotes.co.za/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook fa-lg"></i>
                    </a>
                    {/* <a href="#" target="_blank">
                      <i className="fa fa-twitter fa-lg"></i>
                    </a>
                    <a href="#" target="_blank">
                      <i className="fa fa-linkedin fa-lg"></i>
                    </a> */}
                    {settingsState.supportPhoneNumber
                      .replace(/ /g, "")
                      .split(",")
                      .map((p: string) => (
                        <a target="_blank" href={`https://wa.me/${p}`}>
                          <i className="fa fa-whatsapp fa-lg"></i>
                          {p}
                        </a>
                      ))}
                    {/* <a href="#" target="_blank">
                      <i className="fa fa-instagram fa-lg"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
