import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { SettingsContext } from "../utils/hooks/context/settings-context-hooks";

export const HeaderComponent: FC = () => {
  const { settingsState } = useContext(SettingsContext);

  return (
    <header className="transparent scroll-dark">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex sm-pt10">
              <div className="de-flex-col">
                <div id="logo">
                  <Link to="/">
                    <img
                      alt=""
                      src="/assets/images/logo.jpeg"
                      className="logo-img"
                    />
                  </Link>
                </div>
              </div>

              <div className="de-flex-col header-col-mid">
                <ul id="mainmenu">
                  <li>
                    <Link to="/">
                      Home<span></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      Services<span></span>
                    </Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://dashboard.enviroquotes.co.za/"
                    >
                      Become a Service Provider
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://dashboard.enviroquotes.co.za/sign-in"
                    >
                      Sign In
                    </a>
                  </li>
                </ul>

                <div className="menu_side_area">
                  <Link to="/create-quote" className="btn-main">
                    <i className="icon_wallet_alt"></i>
                    <span>Request a quote</span>
                  </Link>
                  <a
                    href={`tel:${
                      settingsState.supportPhoneNumber
                        .replace(/ /g, "")
                        .split(",")[0]
                    }`}
                    style={{ marginLeft: 20 }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={
                      settingsState.supportPhoneNumber
                        .replace(/ /g, "")
                        .split(",")[0]
                    }
                  >
                    <i className="icon_phone"></i>
                  </a>
                  <a
                    href={`mailto:${settingsState.supportEmail.split(",")[0]}`}
                    target="_blank"
                    style={{ marginLeft: 20 }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={settingsState.supportEmail.split(",")[0]}
                  >
                    <i className="icon_mail"></i>
                  </a>
                  <span id="menu-btn"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
