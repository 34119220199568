import { FC, useContext } from "react";
import { MetaTagComponent } from "../components/meta-tag-component";
import { SettingsContext } from "../utils/hooks/context/settings-context-hooks";

export const FaqsPage: FC = () => {
  const { settingsState } = useContext(SettingsContext);

  return (
    <>
      <MetaTagComponent
        {...{
          keywords: "faqs",
          title: "Enviro Quotes | FAQs",
          description:
            "Enviro Quotes provides provides satisfying answers to most of the questions relating to our services.",
          url: "http://enviroquotes.co.za/faqs",
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>FAQ(S)</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>

      {settingsState.loading ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <section aria-label="section">
          <div className="container">
            <span>
              <i className="id-color fa fa-file-text fa-lg"></i> FAQ(S)
            </span>

            <div
              className="mt-3"
              dangerouslySetInnerHTML={{ __html: settingsState.faqs! }}
            />
          </div>
        </section>
      )}
    </>
  );
};
