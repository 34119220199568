import { FC, useEffect, useState } from "react";
import { MetaTagComponent } from "../../components/meta-tag-component";
import { notify } from "../../utils/functions/notification";
import { UseApiWithOutTokenService } from "../../utils/hooks/custom/api-with-out-token-http-hook";

export const ServicesPage: FC = () => {
  const { get } = UseApiWithOutTokenService(),
    [state, setActualState] = useState({
      loading: true,
      data: [],
      filtered: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getServices = () => {
      setState({ loading: true });
      get("sub-service", {
        filter: true,
        sort: "asc",
        sortName: "name",
        pageSize: 9999999999,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              data: data.data,
              filtered: data.data,
              loading: false,
            });
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    };

  useEffect(() => getServices(), []);

  return (
    <>
      <MetaTagComponent
        {...{
          keywords: "services",
          title: "Enviro Quotes | Services",
          url: "http://enviroquotes.co.za/services",
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/2.png) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Services</h1>
          </div>
        </div>
      </section>

      <section className="no-bottom no-top">
        <div className="v-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg">
                <div className="text_top">
                  <div className="spacer-double"></div>
                  <h1>Join our great community of elite service providers</h1>
                  <p className="lead">
                    Joining our platform is free, every service provider has a
                    fair opportunity to access available work, showcase their
                    skills and provide the clients with the best services, and
                    at an affordable rate.
                  </p>
                  <div className="spacer-20"></div>
                  <a
                    href="https://dashboard.enviroquotes.co.za/"
                    className="btn-main"
                  >
                    Become a Service Provider
                  </a>
                  <div className="spacer-double"></div>
                </div>
              </div>
              <div className="col-md-5 xs-hide">
                <div className="d-carousel">
                  <div
                    id="item-carousel-big-type-2"
                    className="owl-carousel wow fadeIn"
                  >
                    <div className="nft_pic style-2">
                      <div className="nft_pic_wrap">
                        <img
                          src="/assets/images/carousel/crs-4.png"
                          className="lazy img-fluid"
                          alt="service-image"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <div className="nft_pic style-2">
                      <div className="nft_pic_wrap">
                        <img
                          src="/assets/images/carousel/crs-5.png"
                          className="lazy img-fluid"
                          alt="service-image"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <div className="nft_pic style-2">
                      <div className="nft_pic_wrap">
                        <img
                          src="/assets/images/carousel/crs-6.png"
                          className="lazy img-fluid"
                          alt="service-image"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <div className="nft_pic style-2">
                      <div className="nft_pic_wrap">
                        <img
                          src="/assets/images/carousel/crs-7.png"
                          className="lazy img-fluid"
                          alt="service-image"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-arrow-left">
                    <i className="fa fa-angle-left"></i>
                  </div>
                  <div className="d-arrow-right">
                    <i className="fa fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section aria-label="section" className="no-top">
        <div className="container">
          <div className="row wow fadeIn">
            <div className="col-lg-12 mb-5">
              <div className="items_filter">
                <form
                  className="row form-dark"
                  id="form_quick_search"
                  method="post"
                  name="form_quick_search"
                >
                  <div className="col">
                    <h3 className="mb-3">Services</h3>
                    <input
                      className="form-control"
                      id="name_1"
                      name="name_1"
                      placeholder="search item here..."
                      type="text"
                      onChange={(e) => {
                        setState({
                          filtered: state.data.filter((d: any) =>
                            d.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          ),
                        });
                      }}
                    />
                    <a href="#" id="btn-submit">
                      <i className="fa fa-search bg-color-secondary"></i>
                    </a>
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>

            {state.loading ? (
              <div className="col-12 d-flex justify-content-center">
                <div className="spinner"></div>
              </div>
            ) : (
              <div className="row">
                {state.filtered.map((d: any) => (
                  <div className="col-md-3 mb-3">
                    <i className="fa fa-cog"></i> {d.name}
                  </div>
                ))}
              </div>
            )}

            {/* <div className="col-md-12 text-center">
              <a href="#" id="loadmore" className="btn-main wow fadeInUp lead">
                Load more
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
