import { FC, useContext } from "react";
import { MetaTagComponent } from "../components/meta-tag-component";
import { SettingsContext } from "../utils/hooks/context/settings-context-hooks";

export const TermsPage: FC = () => {
  const { settingsState } = useContext(SettingsContext);
  return (
    <>
      <MetaTagComponent
        {...{
          keywords: "terms and conditions",
          title: "Enviro Quotes | Terms & Conditions",
          description: "Enviro Quotes terms of use.",
          url: "http://enviroquotes.co.za/terms-and-conditions",
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Terms & Conditions</h1>
          </div>
        </div>
      </section>

      {settingsState.loading ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <section aria-label="section">
          <div className="container">
            <span>
              <i className="id-color fa fa-file-text fa-lg"></i> Terms &
              Conditions
            </span>

            <div
              className="mt-3"
              dangerouslySetInnerHTML={{ __html: settingsState.terms! }}
            />
          </div>
        </section>
      )}
    </>
  );
};
