import { useState, createContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { io } from "socket.io-client";
import { BASE_URL2 } from "../../constant";
import { notify } from "../../functions/notification";
import { ApiResponseInterface } from "../../interfaces/shared-interface";
import { getSocketId } from "../../functions/upt-function";

const socket = io(BASE_URL2);

export const SocketContext = createContext<any>({
  socketError: false,
  setSocketState: (data: any) => {},
  data: {},
  type: "",
  url: "",
  message: "",
  recall: [],
});

export const SocketProvider = withRouter((props: any) => {
  const businessId = window.location.pathname.split("/")[2],
    [state, setActualState] = useState<any>({
      error: false,
      data: {},
      type: "",
      url: "",
      message: "",
      recall: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    error = () => {
      socket.on(`${getSocketId()}-error`, (data: ApiResponseInterface) => {
        notify("error", data.message);

        console.log("error", data);

        if (
          data.message.toLowerCase().includes("section has expired") ||
          data.message.toLowerCase().includes("access denied")
        ) {
          setState({ error: true });
        }
      });
    },
    reset = () => {
      state.data = {};
      state.type = "";
      state.url = "";
      state.message = "";
      setState(state);
    },
    response = (link: string) => {
      socket.on(
        `${getSocketId()}-${link}-result`,
        (res: ApiResponseInterface) => {
          state.data = res.data;
          state.type = "success";
          state.url = `${getSocketId()}-${link}`;
          setState(state);
        }
      );

      state.recall = state.recall.filter(
        (r: any) => r.type === "socket" && r.link !== link
      );
    },
    setSocketState = ({ error }: any) => setState({ error }),
    get = (link: string, params?: any, id?: string) => {
      reset();
      if (
        params &&
        params.filter! &&
        typeof params.searchFilters === "object"
      ) {
        params.searchFilters = JSON.stringify(params.searchFilters);
      }

      const data = {
        ...(params && { query: params }),
        ...(id && { id }),
        ...(businessId && { businessId }),
        client: `${getSocketId()}`,
      };

      socket.emit(link, data);
      state.recall = [...state.recall, ...[{ link, data, type: "socket" }]];

      setState(state);

      response(id ? link.replace("/id", `/${id}`) : link);
    };

  useEffect(() => {
    socket.on("connect", () => {
      if (socket.connected) {
        getSocketId();

        for (const r of state.recall.filter(
          (_r: any) => _r.type === "socket"
        )) {
          socket.emit(r.link, r.data);
        }
      }
    });

    error();
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socketError: state.error,
        setSocketState: setSocketState,
        res: {
          data: state.data,
          type: state.type,
          url: state.url,
          message: state.message,
        },
        get: get,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
});
