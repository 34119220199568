export const getSocketId = () => {
  if (window.localStorage.getItem("@socket-id"))
    return window.localStorage.getItem("@socket-id")!;
  else {
    const id = `${Math.random()}-${Math.random()}`;
    window.localStorage.setItem("@socket-id", id);

    return id;
  }
};
