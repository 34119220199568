import { FC, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { MetaTagComponent } from "../../components/meta-tag-component";
import { UseApiWithOutTokenService } from "../../utils/hooks/custom/api-with-out-token-http-hook";
import { notify } from "../../utils/functions/notification";

export const ServicesDetailsPage: FC = () => {
  const { pathname } = useLocation(),
    { id } = useParams<any>(),
    { get } = UseApiWithOutTokenService(),
    [state, setActualState] = useState({
      loading: true,
      name: "",
      description: "",
      serviceId: "",
      pictures: [],
      loading2: true,
      data: [],
      filtered: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true });
      get(
        pathname.includes("sub") ? `sub-service/${id}` : `service/${id}`,
        null
      )
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              ...data,
              ...(pathname.includes("sub") && {
                loading: false,
              }),
            });

            if (!pathname.includes("sub")) getServices();
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    getServices = () => {
      get("sub-service", {
        filter: true,
        sort: "asc",
        sortName: "name",
        pageSize: 9999999999,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
            {
              field: "serviceId",
              option: "eq",
              type: "string",
              data: id,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              data: data.data,
              filtered: data.data,
              loading: false,
            });

            // if (!pathname.includes("sub")) getServices();
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    };

  useEffect(() => {
    setState({ loading: true });
    getData();
  }, [pathname]);

  return (
    <>
      {!state.loading ? (
        <MetaTagComponent
          {...{
            keywords: state.name,
            title: state.name,
            img: state.pictures[0] ? state.pictures[0] : "",
            description: `${
              state.description.replace(/<[^>]*>/g, "").length > 150
                ? `${state.description
                    .replace(/<[^>]*>/g, "")
                    .substr(1, 197)}...}`
                : state.description.replace(/<[^>]*>/g, "")
            }`,
            url: `http://enviroquotes.co.za/${
              pathname.includes("sub") ? `sub-service/${id}` : `service-2/${id}`
            }`,
          }}
        />
      ) : (
        <></>
      )}

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/1.png) bottom"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Services Details</h1>
          </div>
        </div>
      </section>

      {state.loading ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <section aria-label="section" className="mt90 sm-mt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center">
                <img
                  src={state.pictures[0] ? state.pictures[0] : ""}
                  className="img-fluid img-rounded mb-sm-30"
                  alt="service-img"
                />
              </div>
              <div className="col-md-6">
                <div className="item_info">
                  <h2>{state.name}</h2>

                  {/* {pathname.includes("sub") ? (
                    <div className="item_info_counts">
                      <div className="item_info_type">
                        <i className="fa fa-gears"></i>Main Service
                      </div>
                      <div className="item_info_views">
                        {JSON.parse(state.serviceId).name}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )} */}
                  <div className="post-text">
                    <div
                      dangerouslySetInnerHTML={{ __html: state.description! }}
                    />

                    <Link to="/create-quote" className="btn-main mt-5">
                      Request a quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {!pathname.includes("sub") ? (
        <section aria-label="section" className="no-top">
          <div className="container">
            <div className="items_filter mb-5 wow fadeIn">
              <form
                className="row form-dark"
                id="form_quick_search"
                method="post"
                name="form_quick_search"
              >
                <div className="col">
                  <h3 className="mb-3">
                    Example of services our service providers can provide under{" "}
                    {state.name}
                  </h3>
                  <input
                    className="form-control"
                    id="name_1"
                    name="name_1"
                    placeholder="search item here..."
                    type="text"
                    onChange={(e) => {
                      setState({
                        filtered: state.data.filter((d: any) =>
                          d.name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        ),
                      });
                    }}
                  />
                  <a href="#" id="btn-submit">
                    <i className="fa fa-search bg-color-secondary"></i>
                  </a>
                  <div className="clearfix"></div>
                </div>
              </form>
            </div>

            <div className="row">
              {state.filtered.map((d: any) => (
                <div className="col-md-3 mb-3">
                  <div className="text-capitalize">
                    <i className="fa fa-cog"></i> {d.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};
