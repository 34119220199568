import { FC, useEffect, useState } from "react";
import { UseApiWithOutTokenService } from "../../utils/hooks/custom/api-with-out-token-http-hook";
import { notify } from "../../utils/functions/notification";
import { Link, useHistory } from "react-router-dom";
import { MetaTagComponent } from "../../components/meta-tag-component";

export const RequestQuotePage: FC = () => {
  const { get, post } = UseApiWithOutTokenService(),
    { replace } = useHistory(),
    [state, setActualState] = useState<any>({
      loading: false,
      type: "",
      name: "",
      phoneNumber: "",
      description: "",
      address: "",
      email: "",
      date: "",
      beeLevel: "",
      checked: false,
      services: [],
      allServices: [],
      text: "proceed",
      error: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    getServices = () => {
      setState({ loading: true });
      get("sub-service", {
        filter: true,
        sort: "asc",
        sortName: "name",
        pageSize: 99999999999,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              allServices: data.data,
              loading: false,
            });
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    submit = () => {
      state.error = [];

      if (
        state.name!.replace(/ /g, "").length === 0 ||
        !state.name!.match(/^[a-zA-Z0-9\s]*$/)
      )
        state.error!.push({
          field: "name",
          msg: "Name is required and can only contain alphabet a - z",
        });

      if (state.type!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "type",
          msg: "Kindly select an option",
        });

      if (state.phoneNumber!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "phoneNumber",
          msg: "Your phone number is required",
        });

      if (state.address!.replace(/ /g, "").length === 0)
        state.error!.push({
          field: "address",
          msg: "Your phone number is required",
        });

      if (state.email!.replace(/ /g, "").length === 0)
        state.error.push({
          field: "email",
          msg: "Your Email is required",
        });
      else if (
        !state.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) // eslint-disable-line
      )
        state.error.push({
          field: "email",
          msg: "Your Email is not valid",
        });

      if (document.getElementsByClassName("ql-editor")[0].innerHTML.length < 10)
        state.error!.push({
          field: "description",
          msg: "Description is required.",
        });

      const d: any = document,
        options = d.getElementById("service")?.options;

      for (const o of options) if (o.selected) state.services.push(o.value);

      if (state.services!.length === 0)
        state.error!.push({
          field: "service",
          msg: "Kindly select an option",
        });

      if (state.date!.length === 0)
        state.error!.push({
          field: "date",
          msg: "Kindly select an option",
        });

      if (state.beeLevel!.length === 0)
        state.error!.push({
          field: "beeLevel",
          msg: "Kindly select an option",
        });

      setState(state);

      if (state.error.length === 0 && state.text === "proceed") {
        setState({ text: "processing..." });

        post(`quote/add`, {
          type: state.type,
          name: state.name,
          phoneNumber: state.phoneNumber,
          address: state.address,
          email: state.email,
          date: state.date,
          beeLevel: parseInt(state.beeLevel),
          services: state.services,
          description: `${
            document.getElementsByClassName("ql-editor").length > 0
              ? `${document.getElementsByClassName("ql-editor")[0].innerHTML}`
              : ""
          }`,
          status: "suspended",
        })
          .then((res) => {
            notify(res.type, `${res.message}`);
            if (res.type === "success") setTimeout(() => replace("/"), 1000);
            else setState({ text: "proceed" });
          })
          .catch((err) => {
            setState({ text: "proceed" });
            notify(err, `${err}`);
          });
      }
    };

  useEffect(() => getServices(), []);

  useEffect(() => {
    if (document.getElementById("service")) {
      window.$("#service").select2();
    }
  }, [state]);

  useEffect(() => {
    if (
      window.Quill &&
      document.getElementById("description") &&
      document.getElementsByClassName("ql-toolbar ql-snow").length == 0
    )
      new window.Quill("#description", {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],

            ["clean"],
          ],
        },
        placeholder: "Describe this quote...",
        theme: "snow", // or 'bubble'
      });

    if (document.getElementById("datePicker"))
      window.flatpickr(document.getElementById("datePicker"), {
        minDate: new Date(),
        onClose: (_: any, dateStr: string) => {
          setState({
            date: dateStr,
          });
          document.getElementById("datePicker")?.blur();
        },
      });
  }, [state.loading]);

  return (
    <>
      <MetaTagComponent
        {...{
          keywords: "Request a quote",
          title: "Enviro Quotes | Request a quote",
          description: `Check out what our various service providers have to offer - from environmental, to occupational health and safety, to agricultural and even insurance related assessments.`,
          url: `http://enviroquotes.co.za/create-quote`,
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Request a quote</h1>
          </div>
        </div>
      </section>

      {state.loading ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <section aria-label="section">
          <div className="container">
            <div className="row wow fadeIn">
              <div className="col-lg-7 offset-lg-1">
                <form className="form-border">
                  <div className="field-set">
                    <div className="spacer-single"></div>
                    <h5>Name (Personal / Organization)</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={state.name}
                      onChange={(e) => {
                        state.error = state.error!.filter(
                          (e: any) => e.field !== "name"
                        );
                        state.name = e.target.value;
                        setState(state);
                      }}
                    />
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "name" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-10"></div>
                    <h5>Email</h5>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={state.email}
                      onChange={(e) => {
                        state.error = state.error!.filter(
                          (e: any) => e.field !== "email"
                        );
                        state.email = e.target.value;
                        setState(state);
                      }}
                    />
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "email" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-10"></div>
                    <h5>Phone Number</h5>
                    <input
                      type="text"
                      name="phoneNumber"
                      className="form-control"
                      value={state.phoneNumber}
                      onChange={(e) => {
                        state.error = state.error!.filter(
                          (e: any) => e.field !== "phoneNumber"
                        );
                        state.phoneNumber = e.target.value;
                        setState(state);
                      }}
                    />
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "phoneNumber" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-10"></div>
                    <h5>Address</h5>
                    <textarea
                      name="address"
                      className="form-control"
                      value={state.address}
                      onChange={(e) => {
                        state.error = state.error!.filter(
                          (e: any) => e.field !== "address"
                        );
                        state.address = e.target.value;
                        setState(state);
                      }}
                    />
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "address" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-10"></div>
                    <h5>
                      Is this request for you personally or for an organization?
                    </h5>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setState({
                          error: state.error!.filter(
                            (e: any) => e.field !== "type"
                          ),
                          type: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose...</option>
                      <option
                        value="personal"
                        selected={state.type === "personal"}
                      >
                        Personal
                      </option>
                      <option
                        value="organization"
                        selected={state.type === "organization"}
                      >
                        For An Organization
                      </option>
                    </select>
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "type" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-10"></div>
                    <h5>Service provider BEE level</h5>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setState({
                          error: state.error!.filter(
                            (e: any) => e.field !== "beeLevel"
                          ),
                          beeLevel: `${e.target.value}`,
                        })
                      }
                    >
                      <option value="">Choose...</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map((b) => (
                        <option value={b} selected={state.beeLevel == b}>
                          BEE Level {b}
                        </option>
                      ))}
                      <option value={"0"} selected={state.beeLevel == "0"}>
                        Not Applicable
                      </option>
                    </select>
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "beeLevel" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-10"></div>
                    <h5>Services</h5>
                    <select
                      id="service"
                      className="form-control"
                      multiple={true}
                    >
                      {state.allServices?.map((s: any) => (
                        <option value={s._id}>{s.name}</option>
                      ))}
                    </select>
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "service" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>

                    <h5>When is this quote needed?</h5>

                    <small>
                      (Please allow a minimum of{" "}
                      <span style={{ color: "red" }}>3 days</span> for service
                      providers to review request - contact us directly for
                      urgent requests)
                    </small>

                    <input
                      type="text"
                      id="datePicker"
                      className="form-control flatpickr flatpickr-input active"
                      value={state.date}
                      onChange={(e) =>
                        setState({
                          error: state.error!.filter(
                            (e: any) => e.field !== "date"
                          ),
                          date: e.target.value,
                        })
                      }
                    />

                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "date" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}

                    <div className="spacer-10"></div>

                    <h5>Description</h5>

                    <div
                      id="description"
                      dangerouslySetInnerHTML={{ __html: state.description! }}
                    />
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "description" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}

                    <div className="spacer-single"></div>

                    <div>
                      <input
                        type="checkbox"
                        checked={state.checked}
                        onChange={() =>
                          setState({
                            error: state.error!.filter(
                              (e: any) => e.field !== "checkbox"
                            ),
                            checked: !state.checked,
                          })
                        }
                      />{" "}
                      <a href="/terms-and-conditions" target="_blank">
                        I have read and agreed to the enviroquotes Terms &
                        Conditions
                      </a>
                    </div>
                    {state.error!.map((e: any) => (
                      <div
                        className="invalid-feedback"
                        style={{
                          display: e.field === "agree" ? "block" : "none",
                        }}
                      >
                        {e.msg}
                      </div>
                    ))}
                    <div className="spacer-single"></div>
                    <input
                      type="button"
                      id="submit"
                      className="btn-main"
                      value={state.text}
                      onClick={() => submit()}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
