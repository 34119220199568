export const loaded = (data: Function) => {
  if (document.readyState === "complete") data();
  else
    document.addEventListener("readystatechange", (e: any) => {
      if (e.target.readyState === "complete") {
        document.removeEventListener("readystatechange", () => {}, false);
        loaded(data);
      }
    });
};
