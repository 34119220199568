import { Index } from "./pages/index";
import { SocketProvider } from "./utils/hooks/context/socket-context-hooks";
import { initializeApp } from "firebase/app";
import { SettingsProvider } from "./utils/hooks/context/settings-context-hooks";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyB3E3PI_rNg5ZVjf3NxeFkUSWVHXelxeOk",
  authDomain: "enviroquotes1.firebaseapp.com",
  projectId: "enviroquotes1",
  storageBucket: "enviroquotes1.appspot.com",
  messagingSenderId: "702625830534",
  appId: "1:702625830534:web:f9ae3375a7ec15a656e307",
  measurementId: "G-LKWRWCNNN0",
});

declare global {
  interface Window {
    $: any;
    particlesJS: any;
    designesia: any;
    Quill: any;
    flatpickr: any;
  }
}

const App = () => {
  return (
    <SocketProvider>
      <SettingsProvider>
        <Index />
      </SettingsProvider>
    </SocketProvider>
  );
};

export default App;
