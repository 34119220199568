import { FC, useEffect, useLayoutEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { PageNotFound } from "./page-not-found";
import { HeaderComponent } from "../components/header-component";
import { HomePage } from "./home";
import { ServicesPage } from "./services/services";
import { ServicesDetailsPage } from "./services/services-details";
import { BlogPage } from "./blog/blog";
import { BlogDetailsPage } from "./blog/blog-details";
import { FooterComponent } from "../components/footer-component";
import { RequestQuotePage } from "./quote/request-quote";
import { QuoteDetailsPage } from "./quote/quote-details";
import { AboutPage } from "./about";
import { TermsPage } from "./terms";
import { PrivacyPage } from "./privacy";
import { FaqsPage } from "./faqs";
import axios from "axios";
import DeviceDetector from "device-detector-js";
import { UseApiWithOutTokenService } from "../utils/hooks/custom/api-with-out-token-http-hook";

export const Index: FC = () => {
  const { pathname } = useLocation(),
    deviceDetector = new DeviceDetector(),
    { post } = UseApiWithOutTokenService(),
    get = async () => {
      await axios.get(`https://api.ipify.org/?format=json`).then(async (r) => {
        const data = {
          device: deviceDetector.parse(window.navigator.userAgent),
          pages: [{ page: window.location.href }],
        };

        if (localStorage.getItem(`${r.data.ip}`))
          post("analytics/add", {
            ...data,
            geoIp: JSON.parse(localStorage.getItem(`${r.data.ip}`)!),
          });
        else
          await axios
            .get(`https://ipinfo.io/${r.data.ip}/json?token=6339586d259378`)
            .then((r) => {
              localStorage.setItem(`${r.data.ip}`, JSON.stringify(r.data));
              post("analytics/add", { ...data, geoIp: r.data });
            });
      });
    };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.location.href.includes("enviroquotes")) get();

    window.designesia(window.$);

    if (!window.matchMedia("screen and (max-width: 993px)").matches)
      window.$("header").removeClass("header-mobile");
  }, [pathname]);

  return (
    <>
      <HeaderComponent />

      <Switch>
        <Route exact path="/" component={HomePage} />

        <Route exact path="/services" component={ServicesPage} />

        <Route exact path="/services/:id" component={ServicesDetailsPage} />

        <Route exact path="/sub-services/:id" component={ServicesDetailsPage} />

        <Route exact path="/our-principles" component={BlogPage} />

        <Route exact path="/our-principles/:id" component={BlogDetailsPage} />

        <Route exact path="/create-quote" component={RequestQuotePage} />

        <Route exact path="/quote/:id" component={QuoteDetailsPage} />

        <Route exact path="/about" component={AboutPage} />

        <Route exact path="/terms-and-conditions" component={TermsPage} />

        <Route exact path="/privacy-policy" component={PrivacyPage} />

        <Route exact path="/faqs" component={FaqsPage} />

        <Route path="/page-not-found" component={PageNotFound} />

        <Redirect to="/page-not-found" exact />
      </Switch>

      <FooterComponent />
    </>
  );
};
