import { FC, useEffect, useState } from "react";
import { UseApiWithOutTokenService } from "../../utils/hooks/custom/api-with-out-token-http-hook";
import { notify } from "../../utils/functions/notification";
import { Link, useParams } from "react-router-dom";
import { formatNumbers } from "../../utils/functions/format-function";

export const QuoteDetailsPage: FC = () => {
  const { get, put } = UseApiWithOutTokenService(),
    { id } = useParams<any>(),
    [state, setActualState] = useState<any>({
      loading: false,
      loading2: false,
      type: "",
      name: "",
      phoneNumber: "",
      description: "",
      address: "",
      email: "",
      status: "",
      referenceNo: "",
      services: [],
      allServices: [],
      submission: undefined,
      transaction: undefined,
      submissions: [],
      filteredSubmissions: [],
      approvedSubmissions: [],
      filteredApprovedSubmissions: [],
      text: "proceed",
      active: "",
      active2: "",
      activeGroup: "",
      active2Group: "",
      error: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState: any) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true });
      get(`quote/${id}`, null)
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              ...data,
            });

            getTransactions();
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    getServices = () => {
      setState({ loading: true });
      get("sub-service", {
        filter: false,
        sort: "asc",
        sortName: "name",
        pageSize: 20000000,
        pageNumber: 1,
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              allServices: data.data,
            });

            getData();
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    getSubmissions = () => {
      setState({ loading2: true });
      get("submission", {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 99999999,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "quoteId",
              option: "eq",
              type: "string",
              data: id,
            },
            {
              field: "suspended",
              option: "eq",
              type: "boolean",
              data: false,
            },
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            let lowest = 0,
              middle = 0,
              highest = 0,
              submission = data.data.find((s: any) => s.selected),
              submissions = data.data.filter((s: any) => !s.approved),
              active = submissions[0] ? submissions[0]._id : "",
              filteredSubmissions = [],
              activeGroup = "",
              approvedSubmissions = [],
              active2 = "",
              filteredApprovedSubmissions = [],
              activeGroup2 = "",
              loading2 = true;

            if (submissions.length > 10) {
              for (let i = 0; i < submissions.length; i++) {
                const s = submissions[i],
                  average = (s.maxAmount + s.minAmount) / 2;

                if (i === 0) {
                  lowest = average;
                  highest = average;
                } else if (lowest > average) lowest = average;

                if (highest < average) highest = average;

                middle = highest;
              }

              submissions = submissions.map((s: any) => {
                if (s.maxAmount < middle) s.group = "low";
                else if (s.maxAmount >= middle && s.maxAmount < highest)
                  s.group = "mid";
                else s.group = "high";

                return s;
              });

              filteredSubmissions = submissions.filter(
                (s: any) => s.group === "low"
              );

              activeGroup = "low";

              active = filteredSubmissions[0] ? filteredSubmissions[0]._id : "";
            }

            lowest = 0;
            middle = 0;
            highest = 0;

            approvedSubmissions = data.data.filter((s: any) => s.approved);

            active2 = approvedSubmissions[0] ? approvedSubmissions[0]._id : "";

            if (approvedSubmissions.length > 10) {
              for (let i = 0; i < approvedSubmissions.length; i++) {
                const s = approvedSubmissions[i],
                  average = (s.maxAmount + s.minAmount) / 2;

                if (i === 0) {
                  lowest = average;
                  highest = average;
                } else if (lowest > average) lowest = average;

                if (highest < average) highest = average;

                middle = highest;
              }

              approvedSubmissions = approvedSubmissions.map((s: any) => {
                if (s.maxAmount < middle) s.group = "low";
                else if (s.maxAmount >= middle && s.maxAmount < highest)
                  s.group = "mid";
                else s.group = "high";

                return s;
              });

              filteredApprovedSubmissions = submissions.filter(
                (s: any) => s.group === "low"
              );

              activeGroup2 = "low";

              active2 = filteredApprovedSubmissions[0]
                ? filteredApprovedSubmissions[0]._id
                : "";
            }

            loading2 = false;

            setState({
              submission,
              submissions,
              active,
              filteredSubmissions,
              activeGroup,
              approvedSubmissions,
              active2,
              filteredApprovedSubmissions,
              activeGroup2,
              loading2,
            });
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    getTransactions = () => {
      setState({ loading2: true });
      get("transaction", {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 1,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "quoteId",
              option: "eq",
              type: "string",
              data: id,
            },
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              transaction: data.data[0],
              loading: false,
            });

            getSubmissions();
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    submit = (submissionId: string, type = "", retry = false) => {
      state.error = [];

      if (
        type !== "" &&
        (state.amountAgreed!.replace(/ /g, "").length === 0 ||
          isNaN(parseFloat(state.amountAgreed)))
      )
        state.error!.push({
          field: "amountAgreed",
          msg: "Finalized amount is required and can only contain numbers",
        });

      state.loading2 = true;

      setState(state);

      if (state.error.length === 0) {
        put(`quote/update2/${`${id}`}/${submissionId}`, {
          retry,
          ...(type !== "" && {
            amount: parseFloat(state.amountAgreed),
            type,
            ...(state.transaction && {
              invoiceId: state.transaction.invoiceId,
            }),
          }),
        })
          .then((res) => {
            setState({ loading2: false });
            notify(res.type, `${res.message}`);

            if (res.type === "success") {
              getData();
              getSubmissions();
            }
          })
          .catch((err) => {
            setState({ loading2: false });
            notify(err, `${err}`);
          });
      }
    },
    option = (type: string, approved = false) => {
      if (approved) state.activeGroup2 = type;
      else state.activeGroup = type;

      state[approved ? "approvedSubmissions" : "filteredSubmissions"] =
        state.submissions.filter((s: any) => s.group === type);

      state.active = state[
        approved ? "approvedSubmissions" : "filteredSubmissions"
      ][0]
        ? state[approved ? "approvedSubmissions" : "filteredSubmissions"][0]._id
        : 0;
      setState(state);
    };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Quote Details</h1>
          </div>
        </div>
      </section>

      {state.loading ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <section className="section no-bottom">
          <div className="container wow fadeIn">
            <div className="box-rounded mb30" data-bgcolor="#F2F6FE">
              <h3>Quote Information</h3>
              <address className="s1">
                <span>
                  <i className="id-color fa fa-wrench fa-lg"></i>
                  Services
                </span>

                <div className="row mb-5">
                  {state.services.map((s: string) => (
                    <div className="col-md-3 mb-5">
                      <div className="author_list_pp">
                        <Link to={`/services/${s}`}>
                          <img
                            className="lazy"
                            src={
                              state.allServices!.find((al: any) => al._id === s)
                                ?.pictures[0]
                            }
                            alt={
                              state.allServices!.find((al: any) => al._id === s)
                                ?.mane
                            }
                            style={{
                              width: 50,
                              height: 50,
                              objectFit: "cover",
                            }}
                          />
                          {state.status === "bidding" ||
                          state.status === "closed" ? (
                            state.status === "bidding" ? (
                              <i className="id-color fa fa-pause-circle"></i>
                            ) : (
                              <i className="id-color fa fa-check-square"></i>
                            )
                          ) : (
                            <></>
                          )}
                        </Link>
                      </div>
                      <div className="author_list_info">
                        <Link to={`/services/${s}`}>
                          {
                            state.allServices!.find((al: any) => al._id === s)
                              ?.name
                          }
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>

                <span>
                  <i className="id-color fa fa-id-card-o fa-lg"></i>
                  Reference No: {state.referenceNo}
                </span>
                <span className="text-capitalize">
                  <i className="id-color fa fa-user fa-lg"></i>
                  {state.name}
                </span>
                <span>
                  <i className="id-color fa fa-envelope-o fa-lg"></i>
                  {state.email}
                </span>
                <span>
                  <i className="id-color fa fa-phone fa-lg"></i>
                  {state.phoneNumber}
                </span>
                <span>
                  <i className="id-color fa fa-map-marker fa-lg"></i>
                  {state.address}
                </span>

                <span>
                  {state.type === "personal" ? (
                    <>
                      <i className="id-color fa fa-user-circle fa-lg"></i>{" "}
                      Personal
                    </>
                  ) : (
                    <>
                      <i className="id-color fa fa-building fa-lg"></i> For an
                      organization
                    </>
                  )}
                </span>

                <span>
                  <i className="id-color fa fa-file-text fa-lg"></i> Description
                </span>

                <div
                  className="mt-3"
                  dangerouslySetInnerHTML={{ __html: state.description! }}
                />
              </address>
            </div>
          </div>
        </section>
      )}

      {state.loading2 ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {state.submission ? (
            <section className="section container no-top">
              <h3>Service Provider's Details</h3>
              <address className="s1">
                <span className="text-capitalize">
                  <i className="id-color fa fa-user fa-lg"></i>
                  {JSON.parse(state.submission.userId!)?.firstName}{" "}
                  {JSON.parse(state.submission.userId!)?.lastName}
                </span>
                <span>
                  <i className="id-color fa fa-envelope-o fa-lg"></i>
                  {JSON.parse(state.submission.userId!)?.email}
                </span>
                <span>
                  <i className="id-color fa fa-phone fa-lg"></i>
                  {JSON.parse(state.submission.userId!)?.phoneNumbers}
                </span>
                <span className="text-capitalize">
                  <b>
                    R
                    {formatNumbers(parseFloat(`${state.submission.minAmount}`))}{" "}
                    - R
                    {formatNumbers(parseFloat(`${state.submission.maxAmount}`))}
                  </b>
                </span>

                <span>
                  <i className="id-color fa fa-file-text fa-lg"></i> Description
                </span>
                <div
                  className="mt-3"
                  dangerouslySetInnerHTML={{
                    __html: state.submission.description!,
                  }}
                />
              </address>
            </section>
          ) : (
            <>
              {state.approvedSubmissions.length > 0 ? (
                <section
                  className="section"
                  style={{
                    background: "#fafafa",
                  }}
                >
                  <div className="container wow fadeIn">
                    <div className="box-rounded mb30" data-bgcolor="#F2F6FE">
                      <h3>Quotations That Interest You </h3>
                      <div className="spacer30"></div>

                      {state.approvedSubmissions.length > 10 ? (
                        <div className="row mb-3">
                          <div className="col text-center">
                            <button
                              className={`btn btn-sm btn-${
                                state.activeGroup2 === "low" ? "" : "success"
                              } m-2`}
                              onClick={() => {
                                option("low", true);
                              }}
                            >
                              Low
                            </button>
                          </div>
                          <div className="col text-center">
                            <button
                              className={`btn btn-sm btn-${
                                state.activeGroup2 === "mid" ? "" : "primary"
                              } m-2`}
                              onClick={() => {
                                option("mid", true);
                              }}
                            >
                              Average
                            </button>
                          </div>
                          <div className="col text-center">
                            <button
                              className={`btn btn-sm btn-${
                                state.activeGroup2 === "high" ? "" : "info"
                              } m-2`}
                              onClick={() => {
                                option("high", true);
                              }}
                            >
                              High
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="spacer30"></div>

                      <div
                        className="accordion accordion-flush"
                        id="accordionFlush2"
                      >
                        {state[
                          state.approvedSubmissions.length > 10
                            ? "filteredApprovedSubmissions"
                            : "approvedSubmissions"
                        ]!.filter((p: any) => !p.deleted).length > 0 ? (
                          state[
                            state.submissions.length > 10
                              ? "filteredApprovedSubmissions"
                              : "approvedSubmissions"
                          ]!.filter((p: any) => !p.deleted).map(
                            (p: any, i: number) => (
                              <div
                                className="accordion-item"
                                style={{ border: "1px solid #fafafa" }}
                              >
                                <h2
                                  className="accordion-header"
                                  id={`flush-heading2${i}`}
                                >
                                  <button
                                    className={`accordion-button accordion-section-title ${
                                      state.active2 === p._id ? "" : "collapsed"
                                    }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#flush-collapse2${i}`}
                                    aria-expanded={`${
                                      state.active2 === p._id ? "true" : "false"
                                    }`}
                                    aria-controls={`flush-collapse2${i}`}
                                  >
                                    <span className="text-capitalize">
                                      <i className="id-color fa fa-user fa-lg"></i>{" "}
                                      Service Provider Reference NO:{" "}
                                      {JSON.parse(p.userId!)?.referenceNo} -
                                      PRICE: (R
                                      {formatNumbers(
                                        parseFloat(`${p.minAmount}`)
                                      )}{" "}
                                      - R
                                      {formatNumbers(
                                        parseFloat(`${p.maxAmount}`)
                                      )}
                                      ){" "}
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id={`flush-collapse2${i}`}
                                  className={`accordion-collapse collapse ${
                                    state.active2 === p._id ? "show" : ""
                                  }`}
                                  aria-labelledby={`flush-heading${i}`}
                                  data-bs-parent="#accordionFlush2"
                                >
                                  <div className="accordion-body">
                                    <address className="s1">
                                      <span className="text-capitalize">
                                        <b>
                                          R
                                          {formatNumbers(
                                            parseFloat(`${p.minAmount}`)
                                          )}{" "}
                                          - R
                                          {formatNumbers(
                                            parseFloat(`${p.maxAmount}`)
                                          )}
                                        </b>
                                      </span>

                                      <span>
                                        <i className="id-color fa fa-file-text fa-lg"></i>{" "}
                                        Description
                                      </span>
                                      <div
                                        className="mt-3"
                                        dangerouslySetInnerHTML={{
                                          __html: p.description!,
                                        }}
                                      />

                                      {p.approved ? (
                                        <p>
                                          Service Provider{" "}
                                          {JSON.parse(p.userId!)?.referenceNo}{" "}
                                          will contact you or click{" "}
                                          <a
                                            className="active"
                                            onClick={() =>
                                              submit(p._id, "", true)
                                            }
                                          >
                                            here to remind Service Provider{" "}
                                            {JSON.parse(p.userId!)?.referenceNo}
                                          </a>
                                        </p>
                                      ) : (
                                        <></>
                                      )}

                                      <form className="form-border mt-3">
                                        <div className="field-set">
                                          <div className="spacer-single"></div>
                                          <h3>Finalize</h3>
                                          <h5>
                                            How much (R) did you agree on?
                                          </h5>
                                          <input
                                            type="number"
                                            className="form-control mb-3"
                                            placeholder="1000"
                                            style={{ width: "20%" }}
                                            value={state.amountAgreed}
                                            onChange={(e) => {
                                              state.error = state.error!.filter(
                                                (e: any) =>
                                                  e.field !== "amountAgreed"
                                              );
                                              state.amountAgreed =
                                                e.target.value;
                                              setState(state);
                                            }}
                                          />
                                          {state.error!.map((e: any) => (
                                            <div
                                              className="invalid-feedback"
                                              style={{
                                                display:
                                                  e.field === "amountAgreed"
                                                    ? "block"
                                                    : "none",
                                              }}
                                            >
                                              {e.msg}
                                            </div>
                                          ))}
                                          <input
                                            type="button"
                                            id="submit"
                                            className="btn-main mb-3"
                                            value="Finalize"
                                            onClick={() =>
                                              submit(p._id, "finalize")
                                            }
                                          />
                                        </div>
                                      </form>
                                    </address>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <></>
              )}

              {state.submissions.length > 0 ? (
                <section className="section no-top">
                  <div className="container wow fadeIn">
                    <div className="box-rounded mb30" data-bgcolor="#F2F6FE">
                      <h3 className="pt-3">Quotations</h3>
                      <div className="spacer30"></div>

                      {state.submissions.length > 10 ? (
                        <div className="row mb-3">
                          <div className="col text-center">
                            <button
                              className={`btn btn-sm btn-${
                                state.activeGroup === "low" ? "" : "outline-"
                              }success m-2`}
                              onClick={() => {
                                option("low");
                              }}
                            >
                              Low
                            </button>
                          </div>
                          <div className="col text-center">
                            <button
                              className={`btn btn-sm btn-${
                                state.activeGroup === "mid" ? "" : "outline-"
                              }primary m-2`}
                              onClick={() => {
                                option("mid");
                              }}
                            >
                              Average
                            </button>
                          </div>
                          <div className="col text-center">
                            <button
                              className={`btn btn-sm btn-${
                                state.activeGroup === "high" ? "" : "outline-"
                              }info m-2`}
                              onClick={() => {
                                option("high");
                              }}
                            >
                              High
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="spacer30"></div>

                      <div
                        className="accordion accordion-flush"
                        id="accordionFlush"
                      >
                        {state[
                          state.submissions.length > 10
                            ? "filteredSubmissions"
                            : "submissions"
                        ]!.filter((p: any) => !p.deleted).length > 0 ? (
                          state[
                            state.submissions.length > 10
                              ? "filteredSubmissions"
                              : "submissions"
                          ]!.filter((p: any) => !p.deleted).map(
                            (p: any, i: number) => (
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`flush-heading${i}`}
                                >
                                  <button
                                    className={`accordion-button accordion-section-title ${
                                      state.active === p._id ? "" : "collapsed"
                                    }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#flush-collapse${i}`}
                                    aria-expanded={`${
                                      state.active === p._id ? "true" : "false"
                                    }`}
                                    aria-controls={`flush-collapse${i}`}
                                  >
                                    <span className="text-capitalize">
                                      <i className="id-color fa fa-user fa-lg"></i>{" "}
                                      Service Provider Reference NO:{" "}
                                      {JSON.parse(p.userId!)?.referenceNo} -
                                      PRICE: (R
                                      {formatNumbers(
                                        parseFloat(`${p.minAmount}`)
                                      )}{" "}
                                      - R
                                      {formatNumbers(
                                        parseFloat(`${p.maxAmount}`)
                                      )}
                                      ){" "}
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id={`flush-collapse${i}`}
                                  className={`accordion-collapse collapse ${
                                    state.active === p._id ? "show" : ""
                                  }`}
                                  aria-labelledby={`flush-heading${i}`}
                                  data-bs-parent="#accordionFlush"
                                >
                                  <div className="accordion-body">
                                    <address className="s1">
                                      <span className="text-capitalize">
                                        <b>
                                          R
                                          {formatNumbers(
                                            parseFloat(`${p.minAmount}`)
                                          )}{" "}
                                          - R
                                          {formatNumbers(
                                            parseFloat(`${p.maxAmount}`)
                                          )}
                                        </b>
                                      </span>

                                      <span>
                                        <i className="id-color fa fa-file-text fa-lg"></i>{" "}
                                        Description
                                      </span>
                                      <div
                                        className="mt-3"
                                        dangerouslySetInnerHTML={{
                                          __html: p.description!,
                                        }}
                                      />
                                      {p.approved ? (
                                        <p>
                                          Service Provider{" "}
                                          {JSON.parse(p.userId!)?.referenceNo}{" "}
                                          will contact you or click{" "}
                                          <a
                                            className="active"
                                            onClick={() =>
                                              submit(p._id, "", true)
                                            }
                                          >
                                            here to remind Service Provider{" "}
                                            {JSON.parse(p.userId!)?.referenceNo}
                                          </a>
                                        </p>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn-main"
                                          onClick={() => submit(p._id)}
                                        >
                                          <i className="id-color fa fa-check-square fa-lg"></i>{" "}
                                          select this quote
                                        </button>
                                      )}
                                    </address>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
