import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BlogComponent } from "../components/blog-component";
import { particles2 } from "../utils/functions/jQuery-functions";
import { MetaTagComponent } from "../components/meta-tag-component";
import { UseApiWithOutTokenService } from "../utils/hooks/custom/api-with-out-token-http-hook";
import { notify } from "../utils/functions/notification";

const $ = window.$;

export const HomePage: FC = () => {
  const [state, setActualState] = useState({
      loading: true,
      loading2: true,
      loading3: true,
      main: [],
      data: [],
      data2: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    { get } = UseApiWithOutTokenService(),
    getServices = () => {
      setState({ loading: true });
      get("service", {
        filter: true,
        sort: "asc",
        sortName: "createdAt",
        pageSize: 999999999,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "featured",
              option: "eq",
              type: "boolean",
              data: true,
            },
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              main: data.data,
              loading: false,
            });
            window?.designesia(window.$);
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    getServices2 = () => {
      setState({ loading2: true });
      get("sub-service", {
        filter: true,
        sort: "asc",
        sortName: "name",
        pageSize: 12,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "featured",
              option: "eq",
              type: "boolean",
              data: true,
            },
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              data: data.data,
              loading2: false,
            });
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    },
    getBlog = () => {
      setState({ loading3: true });
      get("blog", {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 3,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      })
        .then(({ type, message, data }) => {
          if (type == "success") {
            setState({
              data2: data.data,
              loading3: false,
            });
          } else notify(type, `${message}`);
        })
        .catch((err) => notify("error", `${JSON.stringify(err)}`));
    };

  useEffect(() => {
    // particles();
    particles2();

    getServices();
    getServices2();
    getBlog();
  }, []);

  return (
    <>
      <MetaTagComponent
        {...{
          title: "Enviro Quotes",
          url: "http://enviroquotes.co.za/",
          img: "https://firebasestorage.googleapis.com/v0/b/enviroquotes1.appspot.com/o/files%2Flogo.jpeg?alt=media&token=4a4af0fa-bc97-459c-a0c0-2bbf2ba31086",
          description:
            "Enviro Quotes is a platform to source free quotes for your environmental and OHS services needs. Giving all service providers equal opportunity to access available work.",
        }}
      />

      <section
        style={{ background: "var(--primary-color)" }}
        className="pb-4 d-none d-lg-block"
      />

      <section className="p-5 pt-3 pb-3 info">
        <div className="row">
          <div className="col d-flex align-items-center order-last order-lg-first">
            {/* <h2>We Are Currently Registering Service Providers</h2> */}
            <h2
              style={{
                letterSpacing: "5px",
                wordSpacing: "1rem",
                textTransform: "uppercase",
              }}
            >
              We Are Open For Business
            </h2>
          </div>
          <div className="col info-col">
            <img src="/assets/images/carousel/crs-5.png" alt="service" />
            <img
              className="d-none d-lg-block"
              src="/assets/images/carousel/crs-3.png"
              alt="service"
            />
            <img
              className="d-none d-lg-block"
              src="/assets/images/carousel/crs-7.png"
              alt="service"
            />
          </div>
        </div>
      </section>

      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <section
          id="section-hero"
          aria-label="section"
          className="pt20 pb20 vh-100"
          data-bgimage="url(/assets/images/background/7.png) bottom"
        >
          <div className="v-center">
            <div id="particles-2-js"></div>
            <div className="container">
              <div className="col-md-6">
                <div className="spacer-single"></div>
                <div className="spacer-10"></div>
                <h1 className="wow fadeInUp" data-wow-delay=".75s">
                  <span className="id-color-2">Enviro Quotes</span>
                </h1>
                <p className="wow fadeInUp lead" data-wow-delay="1s">
                  A free platform to source quotes for Environment, Occupational
                  Health and Safety, and Agriculture services. Giving all
                  service providers equal access to available work
                  opportunities.
                </p>
                <div className="spacer-10"></div>

                {/* <div className="row">
                  <div
                    className="col-lg-4 wow fadeInRight mb30"
                    data-wow-delay="1.1s"
                  >
                    <div className="de_count text-left">
                      <h3>
                        <span className="id-color-2">94,215k</span>
                      </h3>
                      <h5 className="id-color">Quotes</h5>
                    </div>
                  </div>

                  <div
                    className="col-lg-4 wow fadeInRight mb30"
                    data-wow-delay="1.4s"
                  >
                    <div className="de_count text-left">
                      <h3>
                        <span className="id-color-2">423</span>
                      </h3>
                      <h5 className="id-color">Vendors</h5>
                    </div>
                  </div>
                </div> */}

                <div className="spacer-10"></div>

                <div className="row">
                  <div className="col-lg-5 mb-5">
                    <Link
                      to="/create-quote"
                      className="btn-main wow fadeInUp lead"
                    >
                      Request a quote
                    </Link>
                  </div>
                  <div className="col-md">
                    <a
                      target="_blank"
                      href="https://dashboard.enviroquotes.co.za/"
                      className="btn-main btn-white wow fadeInUp lead"
                    >
                      Become a Service Provider
                    </a>
                  </div>
                </div>
                <div className="mb-sm-30"></div>
              </div>
            </div>
          </div>
        </section>

        <section className="no-bottom no-top">
          <h2
            className="wow fadeInUp text-center justify-content-center d-flex"
            data-wow-delay=".5s"
          >
            <span className="p-4 mb-4 d-block id-color-2">
              "your market for environmental & OHS services quotes"
            </span>
          </h2>

          {state.loading ? (
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              <div className="d-carousel mb-5">
                <div id="item-carousel-big" className="owl-carousel wow fadeIn">
                  {state.main.map((s: any) => (
                    <div className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                      <div className="nft_pic">
                        <Link to={`/services/${s._id}`}>
                          <span className="nft_pic_info">
                            <span className="nft_pic_title">{s.name}</span>
                          </span>
                        </Link>
                        <div className="nft_pic_wrap">
                          <img
                            src={s.pictures[0]}
                            className="lazy img-fluid"
                            alt="service-img"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-arrow-left">
                  <i className="fa fa-angle-left"></i>
                </div>
                <div className="d-arrow-right">
                  <i className="fa fa-angle-right"></i>
                </div>
              </div>
            </>
          )}
        </section>

        <section className="no-top">
          <div
            className="text-light overflow-hidden"
            data-bgimage="url(/assets/images/background/2.png) top"
          >
            {/* <div id="particles-2-js"></div> */}
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="text_top">
                    <div className="spacer-double"></div>
                    <h2>
                      Check out what our various service providers have to offer
                    </h2>
                    <p className="lead">
                      From environmental, to occupational health and safety,
                      <br /> to agricultural and even insurance related
                      assessments.
                    </p>
                    <div className="spacer-20"></div>
                    <Link to="/create-quote" className="btn-main">
                      Request a quote
                    </Link>
                    <div className="spacer-double"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {state.loading2 ? (
          <div className="col-12 d-flex justify-content-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <div className="container wow fadeIn pb-5">
              <div className="text-center">
                <h2>Examples of services our service providers can provide.</h2>
                <div className="small-border bg-color-2"></div>
              </div>
              <ol className="author_list">
                {state.data.map((d: any) => (
                  <li className="mb-5">
                    <div className="author_list_pp">
                      <Link to={`/sub-services/${d._id}`}>
                        <img
                          className="lazy"
                          src={d.pictures[0]}
                          alt={d.mane}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                        <i className="fa fa-check"></i>
                      </Link>
                    </div>
                    <div className="author_list_info">
                      <Link to={`/sub-services/${d._id}`}>{d.name}</Link>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </>
        )}

        <section className="no-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="text-center">
                  <h2>With Just 3 Simple Steps.</h2>
                  <div className="small-border bg-color-2"></div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-sm-30">
                <div className="feature-box f-boxed style-3">
                  <i className="wow fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt"></i>
                  <div className="text">
                    <h4 className="wow fadeInUp">1. Request a quote</h4>
                    <p className="wow fadeInUp" data-wow-delay=".25s">
                      When you click on the 'Request a quote' button on the
                      navigation bar, you will be directed to a screen where you
                      can quickly fill in all the necessary details for the
                      quote you wish to receive.
                    </p>
                  </div>
                  <i className="wm icon_cloud-upload_alt"></i>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-sm-30">
                <div className="feature-box f-boxed style-3">
                  <i className="wow fadeInUp bg-color-2 i-boxed icon_search"></i>
                  <div className="text">
                    <h4 className="wow fadeInUp">2. Review Quotes</h4>
                    <p className="wow fadeInUp" data-wow-delay=".25s">
                      You will be able to view the estimated cost of the
                      different service providers for your request.
                    </p>
                  </div>
                  <i className="wm icon_search"></i>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-sm-30">
                <div className="feature-box f-boxed style-3">
                  <i className="wow fadeInUp bg-color-2 i-boxed icon_ribbon"></i>
                  <div className="text">
                    <h4 className="wow fadeInUp">
                      3. Select a Service Provider
                    </h4>
                    <p className="wow fadeInUp" data-wow-delay=".25s">
                      Then you select a service provider of your choice from the
                      list provided.
                    </p>
                  </div>
                  <i className="wm icon_ribbon"></i>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section data-bgcolor="#F7F4FD">
          <div className="container">
            <div className="text-center">
              <div className="spacer-double"></div>
              <h2>We believe in the following principles</h2>
            </div>
          </div>
        </section>

        {state.loading3 ? (
          <div className="col-12 d-flex justify-content-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <section aria-label="section">
            <div className="container">
              <div className="row">
                {state.data2.map((d: any) => (
                  <BlogComponent {...d} />
                ))}

                {/* <div className="col-12">
                  <div className="text-center">
                    <div className="spacer-20"></div>
                    <Link to="/blog" className="btn-main">
                      See More!
                    </Link>
                    <div className="spacer-double"></div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};
