export const formatNumbers = (x: number) => {
  return `${x
    .toFixed(4)
    .split(".")[0]
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    x.toFixed(4).split(".")[1] === "0000"
      ? ""
      : `.${x.toFixed(4).split(".")[1]}`
  }`;
};
