import { FC } from "react";
import { Link } from "react-router-dom";

export const BlogComponent: FC<any> = ({
  _id,
  name,
  pictures,
  summary,
  createdAt,
}) => {
  return (
    <div className="col-lg-4 col-md-6 mb30">
      <div className="bloglist item">
        <div className="post-content">
          <div className="post-image">
            <img alt={name} src={pictures[0]} className="lazy img" />
          </div>
          <div className="post-text">
            {/* <span className="p-date">{new Date(createdAt).toDateString()}</span> */}
            <h4>
              <Link to={`/our-principles/${_id}`}>
                {name}
                <span></span>
              </Link>
            </h4>

            <div
              dangerouslySetInnerHTML={{
                __html:
                  summary.length > 200
                    ? `${`${window.$(summary).text()}`.substr(0, 197)}...`
                    : summary,
              }}
            />

            <Link className="btn-main mt-3" to={`/our-principles/${_id}`}>
              Read more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
