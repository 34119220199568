import { FC } from "react";
import { Helmet } from "react-helmet";

export const MetaTagComponent: FC<{
  keywords?: string;
  title?: string;
  img?: string;
  description?: string;
  url?: string;
}> = ({ keywords, title, img, description, url }) => {
  return (
    <>
      {/* <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Enviro Quotes" />
    <meta name="twitter:description" content="" />
    <meta
      name="twitter:image"
      href="%PUBLIC_URL%/assets/img/images/logo.jpeg"
    /> */}

      {keywords && keywords.length > 0 ? (
        <Helmet>
          <meta
            name="keywords"
            content={`enviro quotes, environmental services, occupational health and safety, agricultural services, insurance risk assessment, ${keywords}`}
          />
        </Helmet>
      ) : (
        <Helmet>
          <meta
            name="keywords"
            content={`enviro quotes, environmental services, occupational health and safety, agricultural services, insurance risk assessment`}
          />
        </Helmet>
      )}

      {title && title.length > 0 ? (
        <Helmet>
          <meta property="og:title" content={`${title}`} />
        </Helmet>
      ) : (
        <></>
      )}

      {img && img.length > 0 ? (
        <Helmet>
          <meta property="og:image" content={`${img}`} />
        </Helmet>
      ) : (
        <Helmet>
          <meta
            property="og:image"
            content={`${`${process.env.PUBLIC_URL}/assets/img/images/logo.jpeg`}`}
          />
        </Helmet>
      )}

      {description && description.length > 0 ? (
        <Helmet>
          <meta
            property="og:description"
            content={`${description} last updated: ${new Date().toDateString()}`}
          />
        </Helmet>
      ) : (
        <></>
      )}

      {url && url.length > 0 ? (
        <Helmet>
          <meta property="og:url" content={`${url}`} />
        </Helmet>
      ) : (
        <></>
      )}
    </>
  );
};
