import { FC, useContext } from "react";
import { MetaTagComponent } from "../components/meta-tag-component";
import { SettingsContext } from "../utils/hooks/context/settings-context-hooks";

export const AboutPage: FC = () => {
  const { settingsState } = useContext(SettingsContext);

  return (
    <>
      <MetaTagComponent
        {...{
          keywords: "about",
          title: "Enviro Quotes | About",
          description:
            "Enviro Quotes provides you with an easy way to Request a quote from the best service providers, to suit your needs. Our service providers are waiting to provide you with the most competitive quotes to meet your needs.",
          url: "http://enviroquotes.co.za/about",
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>About Us</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>

      {settingsState.loading ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <section aria-label="section">
          <div className="container">
            <div className="col-lg-6">
              <div className="spacer-single"></div>
              <h6 className="wow fadeInUp" data-wow-delay=".5s">
                <span className="text-uppercase id-color-2">About</span>
              </h6>
              <div className="spacer-10"></div>
              <h1 className="wow fadeInUp" data-wow-delay=".75s">
                <span className="id-color-2">Enviro Quotes</span> provides you
                with an easy way to request quotations from accredited service
                providers, to suit your needs.
              </h1>
              <div className="spacer-double"></div>
            </div>

            <span>
              <i className="id-color fa fa-file-text fa-lg"></i> About Us
            </span>

            <div
              className="mt-3"
              dangerouslySetInnerHTML={{ __html: settingsState.about! }}
            />
          </div>
        </section>
      )}
    </>
  );
};
