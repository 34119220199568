import { FC } from "react";
import { useHistory } from "react-router-dom";
import { MetaTagComponent } from "../components/meta-tag-component";

export const PageNotFound: FC = () => {
  const { goBack } = useHistory();
  return (
    <>
      <MetaTagComponent
        {...{
          title: "Page Not Found",
          url: "http://enviroquotes.co.za/",
          img: "https://firebasestorage.googleapis.com/v0/b/enviroquotes1.appspot.com/o/files%2Flogo.jpeg?alt=media&token=4a4af0fa-bc97-459c-a0c0-2bbf2ba31086",
          description:
            "Enviro Quotes is a platform to source free quotes for your environmental and OHS services needs. Giving all service providers equal opportunity to access available work.",
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>4👀4</h1>
          </div>
        </div>
      </section>

      <div className="error404 text-center mt-5">
        <div className="container-fluid error-content mt-5">
          <div className="mt-5">
            <h1 className="error-number">404</h1>
            <p className="mini-text">👀 Ooops!</p>
            <p className="error-text mb-4 mt-1">
              The page you requested was not found!
            </p>
            <button
              className="btn btn-outline-secondary mt-5 mb-5 mr-3"
              onClick={() => goBack()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
