import Snackbar from "node-snackbar";

export const notify = (type: string, msg: string) =>
  Snackbar.show({
    text: msg,
    pos: "top-right",
    actionTextColor: "#fff",
    backgroundColor:
      type === "success" ? "#1abc9c" : type === "info" ? "#2196f3" : "#e7515a",
  });
