import { FC, useContext } from "react";
import { MetaTagComponent } from "../components/meta-tag-component";
import { SettingsContext } from "../utils/hooks/context/settings-context-hooks";

export const PrivacyPage: FC = () => {
  const { settingsState } = useContext(SettingsContext);

  return (
    <>
      <MetaTagComponent
        {...{
          keywords: "privacy policy",
          title: "Enviro Quotes | Privacy Policy",
          description:
            "Enviro Quotes care about you see how we protect your data.",
          url: "http://enviroquotes.co.za/privacy-policy",
        }}
      />

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </section>

      {settingsState.loading ? (
        <div className="col-12 d-flex justify-content-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <section aria-label="section">
          <div className="container">
            <span>
              <i className="id-color fa fa-file-text fa-lg"></i> Privacy Policy
            </span>

            <div
              className="mt-3"
              dangerouslySetInnerHTML={{ __html: settingsState.privacy! }}
            />
          </div>
        </section>
      )}
    </>
  );
};
