import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant";
import { ApiResponseInterface } from "../../interfaces/shared-interface";

export const UseApiWithOutTokenService = () => {
  const [_response, setResponse] = useState<ApiResponseInterface>({
    data: {},
    type: "",
    message: "",
  });

  const post = async (link: string, data: any, timeout?: number) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .post(`${BASE_URL}/${link}`, data, {
        timeout: timeout ? timeout : 60000,
      })
      .then((response) => {
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });

    return _response;
  };

  const get = async (link: string, params: any, timeout?: number) => {
    if (params && params.filter) {
      params.searchFilters = JSON.stringify(params.searchFilters);
    }

    await axios
      .get(`${BASE_URL}/${link}`, {
        ...(params && {
          params: params,
        }),
        timeout: timeout ? timeout : 60000,
      })
      .then((response) => {
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        console.log(error);

        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });

    return _response;
  };

  const put = async (link: string, data: any, timeout?: number) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .put(`${BASE_URL}/${link}`, data, {
        timeout: timeout ? timeout : 60000,
      })
      .then((response) => {
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });

    return _response;
  };

  const Delete = async (link: string, timeout?: number) => {
    setResponse({
      data: {},
      type: "",
      message: "",
    });

    await axios
      .delete(`${BASE_URL}/${link}`, {
        timeout: timeout ? timeout : 60000,
      })
      .then((response) => {
        _response.data = response.data.data;
        _response.message = response.data.message;
        _response.type = "success";

        setResponse(_response);
      })
      .catch((error) => {
        _response.type = "error";
        _response.message =
          !error.response && error.message === "Network Error"
            ? "Invalid URL or Network Error Please check your internet connection"
            : error.response?.data.message;
        _response.data = error.response?.data.data;
        setResponse(_response);
      });

    return _response;
  };

  return { post, get, put, Delete };
};
