import { FC, useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { SocketContext } from "../../utils/hooks/context/socket-context-hooks";
import { getSocketId } from "../../utils/functions/upt-function";
import { MetaTagComponent } from "../../components/meta-tag-component";
import { SettingsContext } from "../../utils/hooks/context/settings-context-hooks";

export const BlogDetailsPage: FC = () => {
  const { socketError, setSocketState, get, res } = useContext(SocketContext),
    { pathname } = useLocation(),
    { id } = useParams<any>(),
    { settingsState } = useContext(SettingsContext),
    [state, setActualState] = useState({
      loading: true,
      loading2: true,
      name: "",
      summary: "",
      description: "",
      link: "",
      pictures: [],
      data: [],
    }),
    setState = (newState: any) => {
      setActualState((currentState) => ({ ...currentState, ...newState }));
    },
    getData = () => {
      setState({ loading: true });
      get("blog-2/id", null, `${id}`);
    },
    getBlog = () => {
      setState({ loading2: true });
      get("blog-2", {
        filter: true,
        sort: "desc",
        sortName: "createdAt",
        pageSize: 3,
        pageNumber: 1,
        searchFilters: {
          searchOption: "AND",
          rules: [
            {
              field: "deleted",
              option: "eq",
              type: "boolean",
              data: false,
            },
          ],
        },
      });
    };

  useEffect(() => {
    getData();
    getBlog();
  }, [pathname]);

  useEffect(() => {
    if (res.url === `${getSocketId()}-blog-2/${id}`) {
      const d = {
        ...state,
        ...res.data,
        loading: false,
      };

      setState(d);
    }

    if (res.url === `${getSocketId()}-blog-2`) {
      setState({
        data: res.data.data,
        loading2: false,
      });
    }
  }, [res.url]);

  useEffect(() => {
    if (socketError) {
      setSocketState({ error: !socketError });
    }
  }, [socketError]);

  return (
    <>
      {!state.loading ? (
        <MetaTagComponent
          {...{
            keywords: state.name,
            title: state.name,
            img: state.pictures[0] ? state.pictures[0] : "",
            description: `${
              state.summary.length > 150
                ? `${state.summary.substr(1, 197)}...}`
                : state.summary
            }`,
            url: `http://enviroquotes.co.za/blog/${id}`,
          }}
        />
      ) : (
        <></>
      )}

      <section
        id="subheader"
        className="text-light"
        data-bgimage="url(/assets/images/background/subheader.jpg) top"
      >
        <div className="center-y relative">
          <div className="container text-center">
            <h1>Our Principles</h1>
          </div>
        </div>
      </section>

      <section aria-label="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {state.loading ? (
                <div className="col-12 d-flex justify-content-center">
                  <div className="spinner"></div>
                </div>
              ) : (
                <>
                  <div className="blog-read">
                    <img
                      alt=""
                      src={state.pictures[0]}
                      className="img-fullwidth rounded"
                    />

                    <div className="post-text">
                      <div
                        dangerouslySetInnerHTML={{ __html: state.description! }}
                      />
                    </div>
                  </div>

                  <div className="spacer-single"></div>
                </>
              )}
            </div>

            <div id="sidebar" className="col-md-4">
              <div className="widget widget-post">
                <h4>Recent Posts</h4>
                <div className="small-border"></div>
                {state.loading2 ? (
                  <div className="col-12 d-flex justify-content-center">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <>
                    <ul>
                      {state.data.map((d: any) => (
                        <li>
                          <span className="date" style={{ marginRight: 10 }}>
                            {new Date(d.createdAt).toDateString()}
                          </span>
                          <Link to={`/our-principles/${d._id}`}> {d.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              <div className="widget widget-text">
                <h4>About Us</h4>
                <div className="small-border"></div>
                <p>
                  Enviroquotes is a free platform where you can reach out to
                  accredited and certified registered service providers with
                  various expertise specialising in Environmental Management
                  Services, and Occupational Health and Safety Management (OHS).
                </p>
                <br />
                <br />
                <Link to="/create-quote" className="btn-main">
                  Request a quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
